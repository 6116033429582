import { trigger, transition, style, animate, query } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import {  } from 'express';
import { PortfolioPeice } from 'src/app/_core/portfolio-pieces.model';

@Component({
  selector: 'app-portfolio-piece',
  templateUrl: './portfolio-piece.component.html',
  styleUrls: ['./portfolio-piece.component.scss'],
  animations: [
    trigger('fadeInLeftOutRight', [
      transition(':enter', [
        query(':enter', [
          style({ transform: 'translate3d(-50%, -20px, 0)', opacity: 0 }),
          animate('500ms ease-in-out', style({ transform: 'translate3d(0, 0, 0)', opacity: 1 })),
          animate('500ms 400ms ease-in-out', style({ transform: 'translate3d(50%, 0, 0)', opacity: 0 })),
        ], { optional: true })
      ]),
    ])
  ]
})

export class PortfolioPieceComponent implements AfterViewInit {

  @Input() piece: any;
  @Input() onScreen: boolean;
  public el: HTMLElement;

  constructor(public elRef: ElementRef) {}

  ngAfterViewInit(): void {
    this.el = this.elRef.nativeElement;
  }

  triggerAnimation(): void {
    this.el.classList.add('animate');
  }

  goToLink(): void {
    window.open(this.piece.link, '_blank');
  }
}