<div [@fadeInLeftOutRight] class="piece">

    <div class="left">
      <img [src]="piece.thumb" [alt]="piece.title" class="thumb">
    </div>
    <div class="right">
      <div class="skills">
        <div class="skill" *ngFor="let skill of piece.skills">
          <p>{{ skill }}</p>
        </div>
      </div>
      <h3>{{ piece.title }}</h3>
      <p>{{ piece.description }}</p>
      <button (click)="goToLink()">{{piece.buttonText}}</button>
    </div>
    <div class="info">
      <div class="spec">
        ROLE
        <p>{{ piece.role }}</p>
      </div>
      <div class="spec">
        PRODUCT
        <p>{{ piece.type }}</p>
      </div>
      <div class="spec">
        CLIENT
        <p>{{ piece.client }}</p>
      </div>
    </div>

</div>